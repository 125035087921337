/* src/components/Projects.css */
.projects-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.projects-heading {
  font-size: 1.1em; 
  margin-top: 0; 
  margin-bottom: 20px;
  color: #b07d62; 
  font-weight: 700; 
  text-align: center; 
}

.project {
  margin-top: 15px;
  margin-bottom: 15px; 
}

.project h3 {
  font-size: 1.2em; 
  margin: 0 0 5px; 
  color: #5e4b3c; 
  font-weight: 500; 
}

.project-subtitle {
  font-size: 0.9em;
  color: #5e4b3c;
  margin: 5px 0 5px; 
}

.project-description {
  margin: 5px 0 5px;
  color: #555;
}

.project-links {
  display: flex;
  gap: 10px;
}

.project-link {
  text-decoration: none; 
  color: #5e4b3c; 
  font-weight: 700; 
}

.project-link:hover {
  color: #b07d62; 
}