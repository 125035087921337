.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 40px); 
}

.content-wrapper {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.App-header {
  width: 100%;
  margin-bottom: 20px;
}

.header-content {
  text-align: left;
}

h1 {
  font-size: 3.5em;
  margin-bottom: 0.25em;
  font-weight: 300;
  letter-spacing: -0.03em;
  line-height: 1;
}

h1 .bold {
  font-weight: 700;
}

.subtitle-container {
  margin-top: -0.5em;
}

h2 {
  font-size: 1.1em;
  margin-top: 0;
  margin-bottom: 0.2em;
  color: #b07d62;
  font-weight: 700; 
}

.location-line {
  font-size: 0.9em;
  color: #5e4b3c;
  margin-top: 0.5em;
}

.location-line span {
  margin-right: 0.5em;
}

nav {
  margin-bottom: 5px; 
  display: flex;
  justify-content: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

nav ul li a {
  text-decoration: none;
  color: #5e4b3c;
  font-weight: 700;
  transition: color 0.3s ease; 
}

nav ul li a:hover,
nav ul li a.active {
  color: #b07d62; 
}

main {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

footer {
  flex-shrink: 0;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

footer a {
  color: #5e4b3c;
  font-size: 24px;
}

footer a:hover {
  color: #b07d62;
}

.home-content {
  text-align: left;
  max-width: 600px;
  margin: 0 auto 40px;
}

.home-content h2:first-child {
  margin-top: 0;
}

.home-content ul {
  list-style-type: none;
  padding-left: 0;
}

.home-content li {
  margin-bottom: 0.5em;
  position: relative;
  padding-left: 1.5em;
}

.home-content li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #b07d62;
}

.home-content h2,
.home-content h3 {
  font-size: 1.1em;
  margin-top: 0;
  margin-bottom: 0.4em;
  color: #5e4b3c;
  font-weight: 550;
}

.home-content h2 {
  font-weight: 400;
  color: #b07d62;
  font-size: 1.2em;
  margin-top: 1em;
  margin-bottom: 0.3em;
}

.home-content h2:first-child {
  margin-top: 0;
}

.home-content a {
  color: inherit;
  text-decoration: none;
  font-weight: 700;
}

.home-content a:hover {
  text-decoration: none; 
}